import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import Skill from "../components/Home/Skill"
import History from "../components/Home/History"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
export default function Template({ data, }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
        <div>
          <Helmet>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <meta
              name="google-site-verification"
              content="JpTpeu5Cv4kcmkhtgWBwqGjOYAUVAhLQOh0DX7A0F5Q"
            />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta
              name="keywords"
              content="Suhas Raja, Suhas, Raja, Suhas Raja Developer, UT, Suhas Raja UT"
            />
            <title>{frontmatter.title}</title>
            <link
              href="https://fonts.googleapis.com/css?family=Questrial|Libre+Baskerville|Montserrat|Overpass|Quicksand&display=swap"
              rel="stylesheet"
            />
            <link
              rel="shortcut icon"
              href="assets/SR_Logo_Thick.png"
              type="image/x-icon"
            />
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
            />
            <script
              src="https://kit.fontawesome.com/1f156684a3.js"
              crossorigin="anonymous"
            />
          </Helmet>
          <section className="markdown-body" style={{background: "#f7efe6", padding:"3em"}}>
            <div
              style={{width:"60%",margin:"auto"}}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </section>
        </div>
  )
}
export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`